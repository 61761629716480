import React from 'react';
import logo from './logo.svg';
import './App.css';

import Home from './screens/Home';

function App() {
  return <Home />
}

export default App;
