import React, { useEffect, useRef } from "react";
import { ReactComponent as Search } from "../../assets/search.svg";
interface SearchBarProps {
  onSearch: (value: string) => void;
  inDashboard?: boolean;
}

const SearchBar = ({ onSearch, inDashboard = false }: SearchBarProps) => {
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && input.current?.value) {
        onSearch(input.current.value);
      }
    };

    input.current?.addEventListener("keypress", handleKeyPress);

    return () => input.current?.removeEventListener("keypress", handleKeyPress);
  }, [input]);

  return (
    <div
      className={`flex w-full ${
        inDashboard ? "flex-row p-16 items-center" : "flex-col items-center"
      }`}
    >
      <span
        className={`${
          inDashboard ? "text-2xl mr-8 xl:text-4xl" : "text-5xl  mb-14"
        }`}
      >
        NFT Viewer
      </span>
      <div
        className={`border border-neutral-900 p-2 rounded-lg flex justify-between ${
          inDashboard ? "w-3/5" : "w-9/12"
        }`}
      >
        <input
          ref={input}
          className="w-full outline-none"
          placeholder="search owner public key"
        />
        <Search
          onClick={() => input.current && onSearch(input.current.value)}
          className="w-5 hover:cursor-pointer"
        />
      </div>
    </div>
  );
};

export default SearchBar;
