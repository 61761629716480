import { useState } from "react";
import { Sorting } from "../services/sortService";

interface State {
  bookmarks: string[];
  sorting: Sorting;
}

const MAX_STEPS = 5;

const useUndoableState = () => {
  const [states, setStates] = useState<State[]>([]);

  const pushState = (state: State, cleanState?: boolean) => {
    const copyStates = cleanState ? [] : [...states];
    const length = copyStates.unshift(state);
    if (length > MAX_STEPS) {
      copyStates.splice(MAX_STEPS);
    }
    setStates(copyStates);
  };

  const goBack = (): [Sorting, string[]] => {
    if (!hasPreviousState()) {
      throw new Error('No previous state');
    }
    const copyStates = [...states];
    copyStates.shift();
    const state = copyStates[0];
    setStates(copyStates);

    return [state.sorting, state.bookmarks];
  }

  const hasPreviousState = () => states.length > 1;

  return { pushState, goBack, hasPreviousState };
};

export default useUndoableState;