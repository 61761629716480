import React, { useEffect, useState } from "react";
import Dashboard from "./Dashboard";
import SortingMenu from "../components/SortingMenu/SortingMenu";

import SearchBar from "../components/SearchBar/SearchBar";
import { getCustom, save, Sorting } from "../services/sortService";
import useUndoableState from "../hooks/useUndoableState";

const Home = () => {
  const [address, setAddress] = useState("");
  const [sorting, setSorting] = useState<Sorting>("lastTxDate");
  const [bookmarks, setBookmarks] = useState<string[]>([]);
  const { hasPreviousState, pushState, goBack } = useUndoableState();

  useEffect(() => {
    if (address) {
      const [sorting, bookmarks] = getCustom(address);
      pushState({ sorting, bookmarks }, true);
      setSorting(sorting);
      setBookmarks(bookmarks);
    }
  }, [address]);

  const updateSorting = (newSorting: Sorting) => {
    save(address, "sorting", newSorting);
    pushState({ sorting: newSorting, bookmarks });
    setSorting(newSorting);
  };

  const updateBookmark = (value: string) => {
    const newBookmarks = [...bookmarks];
    const index = bookmarks.indexOf(value);
    if (index === -1) {
      newBookmarks.push(value);
    } else {
      newBookmarks.splice(index, 1);
    }
    save(address, "bookmarks", newBookmarks);
    pushState({ sorting, bookmarks: newBookmarks });
    setBookmarks(newBookmarks);
  };

  const clearBookmarks = () => {
    save(address, "bookmarks", []);
    pushState({ sorting, bookmarks });
    setBookmarks([]);
  };

  const undoChange = () => {
    const [newSorting, newBookmarks] = goBack();
    save(address, "sorting", newSorting);
    save(address, "bookmarks", newBookmarks);
    setSorting(newSorting);
    setBookmarks(newBookmarks);
  };

  if (address) {
    return (
      <div>
        <SearchBar onSearch={setAddress} inDashboard />
        <div className="flex justify-between px-20">
          <div className="flex">
            <SortingMenu active={sorting} onClick={updateSorting} />
            {hasPreviousState() && (
              <button
                className="border border-neutral-900 rounded-lg py-2 px-5 text-sm "
                onClick={undoChange}
              >
                Undo
              </button>
            )}
          </div>
          {!!bookmarks.length && (
            <button
              className="bg-red-500 text-white rounded-lg py-3 px-5 text-sm"
              onClick={clearBookmarks}
            >
              Clear bookmark
            </button>
          )}
        </div>
        <Dashboard
          address={address}
          sorting={sorting}
          bookmarks={bookmarks}
          updateBookmark={updateBookmark}
        />
      </div>
    );
  }

  return (
    <div className="flex h-screen justify-center items-center">
      <SearchBar onSearch={setAddress} />
    </div>
  );
};

export default Home;
