import React, { useEffect, useState } from "react";

import PlaceholderCard from "../components/NFTCard/Placeholder";
import NFTCard from "../components/NFTCard/NFTCard";
import { getMetadataFromAddress, NFT } from "../services/nftService";
import { getCustom, getSortedNFTs, Sorting } from "../services/sortService";

interface DashboardProps {
  address: string;
  sorting: Sorting;
  bookmarks: string[];
  updateBookmark: (value: string) => void;
}

const Dashboard = ({
  address,
  sorting,
  bookmarks,
  updateBookmark,
}: DashboardProps) => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getNFTsMetadata = async () => {
      try {
        setIsLoading(true);
        const metadata = await getMetadataFromAddress(address);

        const [customSorting, customBookmarks] = getCustom(address);

        const sortedNFTs = getSortedNFTs(
          metadata,
          customSorting,
          customBookmarks
        );
        setNfts(sortedNFTs);
      } catch (e) {
        console.log("error getting nfts", e);
      }
      setIsLoading(false);
    };

    getNFTsMetadata();
  }, [address]);

  useEffect(() => {
    if (nfts.length) {
      const sortedNFTs = getSortedNFTs(nfts, sorting, bookmarks);
      setNfts(sortedNFTs);
    }
  }, [bookmarks, sorting]);

  return (
    <div className="grid grid-cols-2 p-20 gap-y-8 md:grid-cols-3 xl:grid-cols-5">
      {isLoading &&
        Array(7)
          .fill("")
          .map(() => <PlaceholderCard />)}
      {!isLoading &&
        nfts.map((nft) => (
          <NFTCard
            key={nft.id}
            {...nft}
            isBookmarked={bookmarks.includes(nft.id)}
            updateBookmark={updateBookmark}
          />
        ))}
    </div>
  );
};

export default Dashboard;
