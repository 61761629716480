import * as solanaWeb3 from '@solana/web3.js';
import * as borsh from '@project-serum/borsh';
import axios from 'axios';
import { Buffer } from 'buffer';

const TOKEN_PROGRAM_ID = new solanaWeb3.PublicKey("TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA");

interface Value {
  pubkey: solanaWeb3.PublicKey;
  account: solanaWeb3.AccountInfo<solanaWeb3.ParsedAccountData>;
}

interface Creator {
  address: string;
  share: number;
}

interface NFTReponse {
  name: string;
  image: string;
  properties: {
    creators: Creator[]
  };
}

export interface NFT {
  id: string;
  name: string;
  image: string;
  creator: string;
  mint: string;
  creationDate: Date | null;
  lastTxDate: Date | null;
}

const schema = borsh.struct([
  borsh.u8('key'),
  borsh.publicKey('updateAuthority'),
  borsh.publicKey('mint'),
  borsh.str('name'),
  borsh.str('symbol'),
  borsh.str('uri'),
]);

const getPDA = async (token: Value) => {
  const { info: { mint } } = token.account.data.parsed;
  const pkMetadata = new solanaWeb3.PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
  const pkMint = new solanaWeb3.PublicKey(mint);
  const seeds = [Buffer.from('metadata'), pkMetadata.toBytes(), pkMint.toBytes()];

  const [PDA, _] = await solanaWeb3.PublicKey.findProgramAddress(seeds, pkMetadata);

  return PDA;
}


const filterNFTs = (tokens: Value[]) => {
  return tokens.filter(({ account }) => {
    const { info: { tokenAmount } } = account.data.parsed;
    return tokenAmount.amount === "1" && tokenAmount.decimals === 0;
  })
}

const deserialize = async (accountInfo: solanaWeb3.AccountInfo<Buffer>) => {
  const connection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl("mainnet-beta"), "confirmed");
  const decoded = schema.decode(accountInfo.data);
  try {
    const response = await axios.get<NFTReponse>(decoded.uri);
    const { properties: { creators }, name, image } = response.data;
    const { address } = creators[0];

    const txs = await connection.getSignaturesForAddress(decoded.mint);

    const creationTx = txs[txs.length - 1];
    const lastTx = txs[0];

    const creationDate = creationTx?.blockTime ? new Date(creationTx.blockTime * 1000) : null;
    const lastTxDate = lastTx?.blockTime ? new Date(lastTx.blockTime * 1000) : null;

    const nft = {
      id: decoded.mint.toBase58(),
      creator: address,
      name,
      image,
      mint: decoded.mint.toBase58(),
      creationDate,
      lastTxDate,
    };

    return nft
  } catch (e) {
    console.log('error getting nft metadata', e);
    return null;
  }
}

export const getMetadataFromAddress = async (address: string) => {
  const connection = new solanaWeb3.Connection(solanaWeb3.clusterApiUrl("mainnet-beta"), "confirmed");
  const publicKey = new solanaWeb3.PublicKey(address);

  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey,
    {
      programId: TOKEN_PROGRAM_ID
    }
  );

  const filterTokenAccounts = filterNFTs(tokenAccounts.value)

  const pdaPrograms = await Promise.all(filterTokenAccounts.map(async token => await getPDA(token)));

  const metadata: NFT[] = [];

  await Promise.all(pdaPrograms.map(async PDA => {
    const accountInfo = await connection.getAccountInfo(PDA);
    if (!accountInfo) return;
    const nft = await deserialize(accountInfo);
    if (nft) {
      metadata.push(nft);
    }
  }));

  return metadata;
}
