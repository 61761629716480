import React from "react";
import { Sorting } from "../../services/sortService";

interface SortingMenuProps {
  active: Sorting;
  onClick: (sorting: Sorting) => void;
}

const SortingMenu = ({ active, onClick }: SortingMenuProps) => {
  return (
    <div>
      <button
        className={`bg-neutral-300 rounded-lg py-3 px-5 text-sm mr-2 ${
          active === "lastTxDate" && "bg-cyan-600 text-white"
        }`}
        onClick={() => onClick("lastTxDate")}
      >
        Last transaction time
      </button>
      <button
        className={`bg-neutral-300 rounded-lg py-3 px-5 text-sm mr-2 ${
          active === "creationDate" && "bg-cyan-600 text-white"
        }`}
        onClick={() => onClick("creationDate")}
      >
        Last creation time
      </button>
    </div>
  );
};

export default SortingMenu;
