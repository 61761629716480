import { NFT } from "./nftService";

export type Sorting = 'lastTxDate' | 'creationDate';

export type CustomBy = 'bookmarks' | 'sorting';

export const getCustom = (address: string) => {
  if (address) {
      const customStr = window.localStorage.getItem("custom");
      if (customStr) {
        const custom = JSON.parse(customStr);
        if (custom[address]) {
          return [custom[address].sorting, custom[address].bookmarks];
        }
      }
  }
  return ['lastTxDate', []];
}

export const save = (address: string, type: CustomBy, value: Sorting | string[]) => {
  const customStr = window.localStorage.getItem('custom');
  if (!customStr) {
    const newValue = { [address]: { sorting: 'lastTxDate', bookmarks: [], [type]: value } }
    window.localStorage.setItem('custom', JSON.stringify(newValue));
  } else {
    const custom = JSON.parse(customStr);
    const customAddress = custom[address];
    let newValue = {};
    if (customAddress) {
      newValue = { ...custom, [address]: { ...customAddress, [type]: value } };
    } else {
      newValue = { ...custom, [address]: { sorting: 'lastTxDate', bookmarks: [], [type]: value } }
    }
    window.localStorage.setItem('custom', JSON.stringify(newValue));
  }
}

export const getSortedNFTs = (nfts: NFT[], sorting: Sorting, bookmarks: string[] = []) => {

  const bookmarksNFTs: NFT[] = [];
  const nftsCopy = [...nfts];

  bookmarks.forEach(bookmark => {
    let index = undefined;
    const nft = nftsCopy.find((value, idx) => {
      if (value.id === bookmark) {
        index = idx;
        return true;
      }
      return false;
    });
    if (nft && index !== undefined) {
      bookmarksNFTs.push(nft);
      nftsCopy.splice(index, 1);
    }
  })

  nftsCopy.sort((a, b) => (b[sorting]?.getTime() || 0) - (a[sorting]?.getTime() || 0));

  return [...bookmarksNFTs, ...nftsCopy];
}