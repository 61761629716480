import React from "react";
import { ReactComponent as StarLogo } from "../../assets/star.svg";
import { ReactComponent as StarYellowLogo } from "../../assets/star-yellow.svg";

interface NFTCardProps {
  id: string;
  name: string;
  image: string;
  creator: string;
  mint: string;
  isBookmarked?: boolean;
  updateBookmark: (value: string) => void;
}

const NFTCard = ({
  id,
  name,
  image,
  creator,
  mint,
  isBookmarked,
  updateBookmark,
}: NFTCardProps) => {
  const formatCreator = `${creator.slice(0, 4)}...${creator.slice(
    creator.length - 4,
    creator.length
  )}`;

  const onClickNFT = () => {
    window.open(`https://solscan.io/token/${mint}`, "_blank");
  };

  const onClickBookmark = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    updateBookmark(id);
  };

  return (
    <div
      onClick={onClickNFT}
      className="flex flex-col bg-neutral-300 rounded-xl relative w-56 hover:cursor-pointer"
    >
      {isBookmarked ? (
        <StarYellowLogo
          onClick={onClickBookmark}
          className="absolute right-2 top-2"
        />
      ) : (
        <StarLogo
          onClick={onClickBookmark}
          className="absolute right-2 top-2"
        />
      )}
      <img
        src={image}
        alt={`${name} NFT`}
        className="object-cover h-48 rounded-xl"
      />
      <div className="flex flex-col p-3">
        <span className="text-base">{name}</span>
        <span className="text-xs">Creator : {formatCreator}</span>
      </div>
    </div>
  );
};

export default NFTCard;
